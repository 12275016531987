<template>
	<div class="home">
		<carousel 
			:items-to-show="1"
			:wrapAround="true"
			:autoplay="4000"
		>
			<slide v-for="slide in 2" :key="slide">
				<img :src="require('@/assets/banner.png')" alt="Banner">
			</slide>

			<template #addons>
				<navigation />
			</template>
		</carousel>
		<section class="section-2-container">
			<div class="content">
				<div class="section-2">
					<img :src="require('@/assets/vaquinha1.png')" class="vaquinha">
					<div class="texto">
						<p class="titulo">Antes de tudo</p>
						<p class="subtitulo">nós somos</p>
						<p class="hashtag">#loucosporservete</p>
						<p class="conteudo">E, como não seria diferente, a Berry's é o resultado dessa paixão, na qual o objectivo é transformar o mercado de sorvete artesanal, com produtos de qualidade por um preço justo. Existimos para levar alegria!</p>
					</div>
					<img :src="require('@/assets/sorvete.png')" class="sorvete">
				</div>
			</div>
			<div class="ripa"></div>
		</section>
		<section class="section-3-container">
			<div class="content">
				<div class="section-3">
					<p class="titulo">Aqui voce escolhe <br>
						<blue>sem frescura</blue> <br>
						dentre mais de <br>
						<blue>50 sabores</blue><br>
						<blue>Berry's</blue></p>
					
					
					<div class="balao">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span>EXPERIMENTE A NOSSA LINHA ZERO AÇÚCAR!</span>
					</div>
				</div>
			</div>
		</section>
		<section class="section-4-container">
			<div class="content">
				<img :src="require('@/assets/palito.png')" class="palito">
				<carousel 
					:items-to-show="1"
					snapAlign="start"
					:wrapAround="true"
					:breakpoints="carouselBreakpoints"
				>
					<slide v-for="slide in 2" :key="slide">
						<a href="https://www.youtube.com/watch?v=LMp_hZpBZ1s" target="_blank">
							<img src="http://img.youtube.com/vi/LMp_hZpBZ1s/maxresdefault.jpg">
						</a>
					</slide>  

					<template #addons>
						<navigation />
					</template>
				</carousel>
			</div>			
		</section>
		<section class="section-5-container">
			<div class="content">
				<div class="imgs">
					<img :src="require('@/assets/FRD_0353.jpg')" alt="">
					<img :src="require('@/assets/FRD_9227.jpg')" alt="">
				</div>
				<div class="text">
					<p>Seja um<br>
						franqueado <b>Berry's</b><br>
						e faça parte<br>
						de um mercado<br>
						que está em<br>
						<b>constante<br>
						crescimento.</b>
					</p>
					<button>Quero ser um franqueado</button>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import LayoutSite from '../layouts/LayoutSite.vue';

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
	name: 'Home',
    components: {
		Carousel,
		Slide,
		Navigation,
	},
	data() {
		return {
			carouselBreakpoints: {
				768: {
					itemsToShow: 2,
				},
			},
		}
	},
	created() {
		this.$emit('update:layout', LayoutSite);

	},
}
</script>
<style scoped lang="scss">
		
	.home {
		&>.carousel {
			border-bottom: 5px solid #005c9c;

			img {
				width: 100%;
			}
		}
		.section-2-container {
			position: relative;
			height: 750px;
			background-image: url('~@/assets/tijolinhos.png');
			overflow: hidden;

			.section-2 {
				display: flex;
				justify-content: space-between;
				position: relative;
				z-index: 2;

				.vaquinha { 
					width: 450px;
					margin-top: 80px;
					object-fit: contain;
				}
				.texto {
					margin-top: 150px;
					text-align: center;

					.titulo {
						margin-bottom: 0;
						text-align: center;
						font-size: 32px;
						font-weight: 600;
						line-height: 30px;
					}
					.subtitulo {
						font-size: 32px;
						margin: 0;
					}
					.hashtag {
						margin-bottom: 30px;
						font-family: the-barethos;
						font-size: 56px;
						line-height: 30px;
						color: #005c9c;
					}
					.conteudo {
						padding-right: 15px;
						font-size: 18px;
						font-weight: 600;
					}
				}
				.sorvete {
					width: 400px;
					margin-top: 217px;
					object-fit: contain;
				}
			}

			.ripa {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 220px;
				background-image: url('~@/assets/ripa.png');
				background-position: center -260px;
				z-index: 1;
			}
		}
		.section-3-container {
			border-bottom: 5px solid #005c9c;

			.section-3 {
				display: grid;
				grid-template-columns: repeat(12, 1fr);
				grid-template-rows: repeat(12, 1fr);
				align-content: left;
				padding: 30px 0;

				.titulo {
					grid-area: 1 / 1 / 5 / 7;
					justify-self: flex-start;
					font-family: blueberry;
					font-size: 36px;
					text-shadow: 2px 2px 0px white, 3px 3px 0px #005c9c;
					text-align: center;
					line-height: 40px;
					transform: rotate(-5deg);
	
					blue {
						font-size: 62px;
						color: #005c9c;
					}
				}
				.balao {
					position: relative;
					text-align: center;
					width: 100px;
					height: 100px;
					grid-area: 7 / 6 / 9 / 8;
					transform: rotate(-8deg);

					span {
						position: absolute;
						inset: 0;
						display: flex;
						align-items: center;
						background: #91d8f6;
						font-family: villainteamup;
						color: #005c9c;
						font-weight: 600;
						font-size: 19px;
						line-height: 20px;

						&:nth-of-type(2) {
							transform: rotate(15deg);
						}
						&:nth-of-type(3) {
							transform: rotate(30deg);
						}
						&:nth-of-type(4) {
							transform: rotate(45deg);
						}
						&:nth-of-type(5) {
							transform: rotate(60deg);
						}
						&:nth-of-type(6) {
							transform: rotate(75deg);
						}
						&:nth-of-type(7) {
							transform: rotate(90deg);
						}
					}
				}
			}

		}
		.section-4-container {
			border-bottom: 5px solid #005c9c;
			.palito {
				display: block;
				width: 500px;
				margin: -70px auto 0;
			}
			.carousel {

				li {
					margin-bottom: 30px;
					padding: 0 30px;
	
					img {
						width: 100%;
						border-radius: 10px;
					}
				}
			}
		}
		.section-5-container {
			.content {
				display: flex;
				justify-content: center;
				padding-top: 30px;
				padding-bottom: 50px;

				img:nth-of-type(1) {
					width: 230px;
					height: 345px;
					object-fit: cover;
					transform: rotate(-2deg) translate(6px, 4px);
					border-radius: 5px;
				}
				img:nth-of-type(2) {
					width: 330px;
					height: 220px;
					object-fit: cover;
					transform: rotate(-13deg) translate(-60px, 75px);
					border-radius: 5px;
				}
				.text {
					text-align: right;

					p {
						margin-bottom: 50px;
						font-family: montserrat-bolditalic;
						color: #005c9c;
						font-size: 36px;
						line-height: 46px;

						& b {
							font-family: montserrat-blackitalic;
						}
					}
					button {
						background: #005c9c;
						border: 0;
						border-radius: 5px;
						padding: 3px 20px;
						color: white;
						font-weight: 600;
					}
				}
			}
		}
	}
	@media screen and (max-width: 980px) {
		.home { 
			.section-2-container {
				.section-2 {
					justify-content: center;
				}
			}
			.section-5-container {
				.content {
					flex-direction: column;
					gap: 80px;
					text-align: center;

					.text {
						text-align: center;

						p {
							line-height: 36px;
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.home {
			.section-4-container {
				.palito {
					width: 300px;
					margin-top: -40px;
				}
			}
			.section-5-container {
				.content {
					gap: 50px;
					
					img {
						&:nth-of-type(1) {
							transform: rotate(2deg) translate(0px, 0px);
						}
						&:nth-of-type(2) {
							transform: rotate(-6deg) translate(0, -20px);
						}
					}
				}
			}
		}
	}
</style>