<template>
	<div class="lojas">
		<div class="content">
			<h1	class="titulo">Encontre a <b>Berry's mais próxima</b> de você</h1>
			<p class="subtitulo">Descubra onde encontrar a sua nova loja favorita e tenha uma verdadeira experiência Berry's.</p>

			<select>
				<option value="mg">Minas Gerais</option>
				<option value="mg">São Paulo</option>
			</select>

			<div class="lojas-grid">
				<div class="loja">
					<div class="img">
						<img :src="require('@/assets/loja.jpg')" alt="Banner">
					</div>
					<p class="cidade">Montes Claros</p>
					<p class="endereco">AV. Dep. Esteves Rodrigues, 525. São Noberto. Monts Claros - MG - 39401851</p>
					<div class="icons">
						<a href="">
							<i class="fas fa-map-marker-alt"></i>
						</a>
						<a href="">
							<i class="fab fa-instagram"></i>
						</a>
					</div>
				</div>
				<div class="loja">
					<div class="img">
						<img :src="require('@/assets/loja.jpg')" alt="Banner">
					</div>
					<p class="cidade">Montes Claros</p>
					<p class="endereco">AV. Dep. Esteves Rodrigues, 525. São Noberto. Monts Claros - MG - 39401851</p>
					<div class="icons">
						<a href="">
							<i class="fas fa-map-marker-alt"></i>
						</a>
						<a href="">
							<i class="fab fa-instagram"></i>
						</a>
					</div>
				</div>
				<div class="loja">
					<div class="img">
						<img :src="require('@/assets/loja.jpg')" alt="Banner">
					</div>
					<p class="cidade">Montes Claros</p>
					<p class="endereco">AV. Dep. Esteves Rodrigues, 525. São Noberto. Monts Claros - MG - 39401851</p>
					<div class="icons">
						<a href="">
							<i class="fas fa-map-marker-alt"></i>
						</a>
					</div>
				</div>
				<div class="loja">
					<div class="img">
						<img :src="require('@/assets/loja.jpg')" alt="Banner">
					</div>
					<p class="cidade">Montes Claros</p>
					<p class="endereco">AV. Dep. Esteves Rodrigues, 525. São Noberto. Monts Claros - MG - 39401851</p>
					<div class="icons">
						<a href="">
							<i class="fas fa-map-marker-alt"></i>
						</a>
						<a href="">
							<i class="fab fa-instagram"></i>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LayoutSite from '../layouts/LayoutSite.vue';

export default {
	name: 'Lojas',
	created() {
		this.$emit('update:layout', LayoutSite);
	},
}
</script>

<style scoped lang="scss">

	.lojas {
		padding-top: 130px;
		text-align: center;

		.titulo {
			font-family: montserrat-bolditalic;
			font-size: 28px;

			b {
				font-family: montserrat-blackitalic;
			}
		}
		.subtitulo {
			font-family: montserrat-mediumitalic;
			font-size: 12px;
		}
		select {
			border: 3px solid #005c9c;
			border-radius: 15px;
			padding: 7px 25px;
			background: white;
			font-size: 28px;

			option:hover {
				background-color: yellow !important;
				box-shadow: none !important;
			}
		}
		.lojas-grid {
			margin-top: 50px;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-column-gap: 20px;

			.loja {
				margin-bottom: 20px;
				
				.img {
					position: relative;
					width: 100%;
					border-radius: 25px;
					padding-bottom: calc(100vw / 5);
					overflow: hidden;

					img {
						position: absolute;
						inset: 0;
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: top;
					}

				}
				.cidade {
					margin: 10px 0;
					font-family: montserrat-blackitalic;
					color: #005c9c;
					font-size: 22px;
				}
				.endereco {
					min-height: 80px;
					padding: 0 30px;
					font-family: montserrat-mediumitalic;
					font-size: 14px;
				}
				.icons {
					display: flex;
					justify-content: center;
					gap: 15px;

					a {
						color: #005c9c;
						font-size: 38px;
					}
				}
			}
		}
		
	}
	@media screen and (max-width: 768px) {
		.lojas {
			padding-top: 0px;

			.lojas-grid {
				grid-template-columns: 1fr;

				.loja {
					.img {
						padding-bottom: calc(100vw / 1.5);
					}
					.endereco {
						min-height: 60px;
					}
				}
			}
		}
	}
</style>