<template>
	<div class="franqueado">
		<div class="content">
			<div class="topo">
				<div class="esquerda">
					<p class="txt"><b>Faça parte</b><br>do time</p>
					<p class="hashtag">#loucos<br>porsorvete
						<span class="hashtag-sombra">#loucos<br>porsorvete</span>
					</p>
				</div>
				<div class="direita">
					<p class="txt">Seja <b>dono</b> do seu<br> <b>próprio negócio</b><br>com a <b>Berry's!</b></p>
					<p class="txt-mini">Imagine você com a franquia<br>de sorvete artesanal<br><b>que mais cresce no país.</b></p>
				</div>
			</div>

			<div class="conteudo">
				<div class="img">
					<img :src="require('@/assets/sorvetes.jpg')" alt="Chocolate belga">
				</div>
				<div class="form">
					<p>Preencha o formulário abaixo e<br>entraremos em contato com você.</p>
					<form action="">
						<input type="text" name="nome" placeholder="Nome">
						<input type="email" name="email" placeholder="E-mail">
						<input type="text" name="telefone" placeholder="Telefone">
						<input type="text" name="cidade" placeholder="Cidade de interesse da franquia">
						<input type="text" name="estado" placeholder="Estado">
						<textarea name="mensagem" rows="7" placeholder="Mensagem"></textarea>
						<button type="submit">ENVIAR MENSAGEM</button>
					</form>
				</div>
			</div>
	</div>
	</div>
</template>

<script>
import LayoutSite from '../layouts/LayoutSite.vue';

export default {
	name: 'Produtos',
	created() {
		this.$emit('update:layout', LayoutSite);
	},
}
</script>

<style scoped lang="scss">
	.franqueado {
		background: #0169b2;
		padding-top: 130px;

		.topo {
			display: flex;
			justify-content: center;
			gap: 30px;

			.esquerda {
				flex-basis: 300px;
				transform: rotate(-10deg);
				text-align: center;

				.txt {
					margin-bottom: 30px;
					font-size: 22px;
					color: white;
					line-height: 20px;
				}
				.hashtag {
					position: relative;
					font-size: 70px;
					color: #0169b2;
					font-family: the-barethos;
					line-height: 30px;
					text-shadow: 1px 0 white, -1px 0 white, 0px 1px white, 0px -1px white;

					.hashtag-sombra {
						position: absolute;
						inset: 0;
						-webkit-text-stroke: 20px white;
						z-index: -1;
					}
				}
				
			}
			.direita {
				flex-basis: 300px;
				text-align: center;

				.txt {
					font-size: 26px;
					color: white;
					line-height: 24px;
				}
				.txt-mini {
					color: white;
					line-height: 18px;
				}
			}

		}
		.conteudo {
			display: flex;
			margin-top: 100px;
			gap: 50px;

			.img {
				position: relative;
				width: 60%;
				padding-bottom: 60vw;

				img {
					position: absolute;
					inset: 0;
					width: 100%;
				}
			}
			.form {
				width: 40%;

				p {
					margin-bottom: 30px;
					text-align: center;
					color: white;
				}
				form {
					input, textarea {
						width: 100%;
						margin-bottom: 10px;
						border: 2px solid white;
						border-radius: 10px;
						background: transparent;
						padding: 5px;
						color: white;
						resize: none;

						&::placeholder {
							color: #ddd;
						}
						&:focus-visible {
							outline: none;
						}
					}
					button {
						background: white;
						border: 1px solid #0169b2;
						border-radius: 7px;
						padding: 5px 10px;
						color: #0169b2;
						font-weight: 600;
						font-size: 14px;
					}
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.franqueado {
			padding-top: 30px;

			.topo {
				flex-wrap: wrap;
				gap: 50px;

				.esquerda {
					flex-basis: 100%;

					.txt {
						transform: rotate(10deg);
					}
				}
				.direita {
					flex-basis: 100%;
				}
			}
			.conteudo {
				flex-wrap: wrap;
				margin-top: 50px;

				.img {
					order: 2;
					width: 100%;
					padding-bottom: 100vw;
				}
				.form {
					order: 1;
					width: 100%;
				}
			}
		}
	}
</style>