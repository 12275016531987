<template>
	<div class="quem-somos">
		<section>
			<div class="content">
				<div class="d-flex">
					<img :src="require('@/assets/vaquinha22.png')" class="vaquinha">
					<div class="text">
						<h1>Olá, somos a</h1>
						<h1>Berry's!</h1>
						<p>Fundada em novembro de 2019, a Berry's é um <b>sonho</b> que se tornou <b>realidade</b>. Sonhávamos em um lugar apaixonante, como um delicioso sorvete, onde as pessoas pudessem se encontrar e desfrutar de uma <b>experiência única e delisiosa.</b></p>
					</div>
					<img :src="require('@/assets/quem-somos-1.jpg')" alt="" class="img-1">
				</div>
			</div>
		</section>
		<section>
			<div class="content">
				<div class="d-flex">
					<img :src="require('@/assets/quem-somos-2.png')" alt="" class="img-2">
					<div class="text">
						<h1>Para nós, <br>
						o <b>sorvete bom</b><br>
						é <b>descomplicado</b><br>
						e sem frescuras.</h1>
	
						<p>Na Berry's cada detalhe foi pensado para potencializar a experiência do cliente. Cores, texturas, iluminação e trilha sonora remetem ao prazer de se deliciar com um sorvete perfeito.
						<br>
						<br>
						Por isso, os mais de <b>40 sabores</b> são dispostos como self-service e com mais de <b>90 opções</b> de caldas e acréscimos.
						</p>
					</div>
				</div>
			</div>
		</section>
		<section>
			<div class="content">
				<div class="d-flex">
					<div>
						<p>Os <b>milhares de clientes</b> que desfrutam desta experiência compartilham de uma certeza: eles não estão em uma sorveteria qualquer, eles vivem uma <b>apaixonante experiência Berry's!</b></p>
						<img :src="require('@/assets/quem-somos-3.png')" alt="" class="img-3">
					</div>
					<img :src="require('@/assets/quem-somos-4.png')" alt="" class="img-4">
				</div>
			</div>
		</section>
		<section>
			<div class="content">
				<h1>O MELHOR<span>sorvete</span><span>artesanal</span> DO MUNDO<span>!</span></h1>
				<div class="sabores">
					<img :src="require('@/assets/sabor1.jpg')" alt="" class="sabor1">
					<img :src="require('@/assets/sabor2.jpg')" alt="" class="sabor2">
					<img :src="require('@/assets/sabor3.jpg')" alt="" class="sabor3">
					<img :src="require('@/assets/sabor4.jpg')" alt="" class="sabor4">
					<img :src="require('@/assets/sabor5.jpg')" alt="" class="sabor5">
					<img :src="require('@/assets/sabor6.jpg')" alt="" class="sabor6">
				</div>
			</div>
		</section> 
	</div>
</template>

<script>
import LayoutSite from '../layouts/LayoutSite.vue';

export default {
	name: 'Lojas',
	created() {
		this.$emit('update:layout', LayoutSite);
	},
}
</script>

<style lang="scss" scoped>
	.quem-somos {
		font-family: montserrat-mediumItalic;

		section:nth-of-type(1) {			
			background: #005c9c;
			border-top: 5px solid white;
			padding-top: 50px;
			.vaquinha {
				width: 400px;
				height: fit-content;
				z-index: 2;
			}
			.text {
				width: 400px;
				margin-right: 50px;
				margin-left: -50px;
				padding-top: 65px;

				h1 {
					font-family: the-barethos;
					font-size: 100px;
					color: white;
					line-height: 115px;
					text-align: right;
					transform: rotate(-7deg);
				}
				h1:nth-of-type(2) {
					margin-bottom: 60px;
					font-size: 140px;
				}
				p {
					text-align: center;
					color: white;
					font-size: 17px;
				}
			}
			.img-1 {
				width: calc(100% - 800px);
				height: 560px;
				margin-top: 35px;
				margin-bottom: -35px;
				border: 5px solid white;
				border-radius: 40px;
				object-fit: cover;
			}
		}
		section:nth-of-type(2) {			
			background: #005c9c;

			img {
				width: calc(100% - 500px);
				height: 550px;
				margin-top: -20px;
				border: 5px solid white;
				border-radius: 45px;
				object-fit: cover;
			}
			.text {
				width: 500px;
				padding-left: 50px;
				
				h1 {
					margin-bottom: 30px;
					padding-top: 100px;
					color: white;
					text-align: right;
					font-size: 38px;
					line-height: 38px;
	
					b {
						font-family: montserrat-blackitalic;
					}
				}
				p {
					text-align: center;
					color: white;
					font-size: 17px;
				}
			}
		}

		section:nth-of-type(3) {			
			background: #005c9c;
			padding-top: 30px;

			p {
				width: 580px;
				padding: 20px 50px;
				padding-right: 100px;
				text-align: center;
				color: white;
				font-size: 17px;
			}
			.img-3 {
				width: 580px;
				height: 800px;
				margin-right: 15px;
				border: 5px solid white;
				border-radius: 45px;
				object-fit: cover;
			}
			.img-4 {
				width: calc(100% - 565px);
				height: 960px;
				border: 5px solid white;
				border-radius: 45px;
				object-fit: cover;
			}
		}
		section:nth-of-type(4) {			
			background: #005c9c;
			padding-top: 50px;

			h1 {
				color: white;
				font-family: montserrat-blackitalic;
				font-size: 28px;
				text-align: center;
				
				span {
					padding-left: 15px;
					font-family: fair-prosper;
					font-size: 64px;
				}
			}
			.sabores {
				border: 5px solid white;
				border-radius: 45px;
				display: flex;
				flex-wrap: wrap;
				gap: 5px;
				background: white;
				overflow: hidden;
			}

			img {
				width: calc((100% - 10px) / 3);
			}
		}
	}
</style>