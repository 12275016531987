<template>
	<div class="wrapper">
		<nav class="main-header navbar navbar-expand navbar-dark bg-lightblue text-sm text-white">
			<ul class="navbar-nav">
				<li class="nav-item">
					<a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
				</li>
			</ul>

			<ul class="navbar-nav ml-auto">
				<a href="" class="btn btn-danger btn-xs">SAIR</a>
			</ul>
		</nav>
		<aside class="main-sidebar sidebar-dark-primary elevation-4 sidebar-no-expand">
			<a href="" class="brand-link text-sm">
				<img src="https://sorveteberrys.lucasjuneo.com.br/imagens/layout/logos/favicon1639146941-Screenshot_from_2021-12-10_11-35-26.png" class="brand-image img-circle elevation-3">
				<span class="brand-text font-weight-light">Administrador</span>
			</a>

			<div class="sidebar">
				<nav class="mt-2">
					<ul class="nav nav-pills nav-sidebar flex-column nav-compact nav-legacy text-sm" data-widget="treeview" role="menu" data-accordion="false">
						<li class="nav-item">
							<a href="#" class="nav-link">
								<i class="nav-icon fas fa-cog"></i>
								<p>Configuração</p>
							</a>
						</li>
						<li class="nav-item">
						<a href="#" class="nav-link">
						<i class="nav-icon fas fa-tachometer-alt"></i>
						<p>
						Dashboard
						<i class="right fas fa-angle-left"></i>
						</p>
						</a>
						<ul class="nav nav-treeview">
						<li class="nav-item">
						<a href="../../index.html" class="nav-link">
						<i class="far fa-circle nav-icon"></i>
						<p>Dashboard v1</p>
						</a>
						</li>
						<li class="nav-item">
						<a href="../../index2.html" class="nav-link">
						<i class="far fa-circle nav-icon"></i>
						<p>Dashboard v2</p>
						</a>
						</li>
						<li class="nav-item">
						<a href="../../index3.html" class="nav-link">
						<i class="far fa-circle nav-icon"></i>
						<p>Dashboard v3</p>
						</a>
						</li>
						</ul>
						</li>
					</ul>
				</nav>
			</div>
		</aside>
	</div>
</template>

<script>
export default {
	created() {
		document.body.classList.add('control-sidebar-slide-open', 'sidebar-mini', 'layout-fixed')
	},
}
</script>

<style scoped>
	@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
	@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
	@import '~admin-lte/dist/css/adminlte.min.css';
</style>
