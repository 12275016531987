<template>
	<div class="main">
		<div class="content">
			<div class="topo">
				<div class="menu-button" @click="toggleMenu"></div>
				<div class="sociais">
					<i class="fab fa-facebook-f"></i>
					<i class="fab fa-instagram"></i>
					<i class="fab fa-whatsapp"></i>
					<i class="fab fa-youtube"></i>
				</div>
			</div>
		</div>
		<div class="menu-container" @click="toggleMenu">
			<div class="content close-menu">
				<div class="menu">
					<router-link to="/">Home</router-link>
					<router-link to="/quem-somos">Conheça a Berry's</router-link>
					<router-link to="/lojas">Nossas lojas</router-link>
					<div class="img">
						<img :src="require('@/assets/logo.png')" alt="Berry's">
					</div>
					<router-link to="/produtos">Produtos Berry's</router-link>
					<router-link to="/franqueado">Seja um Franqueado</router-link>
					<router-link to="/fale-conosco">Fale Conosco</router-link>
				</div>
			</div>
		</div>
		
		<div class="body">
			<slot/>
		</div>

		<footer class="footer-container">
			<div class="content">
				<div class="footer">
					<img :src="require('@/assets/mini-logo.png')" alt="Berry's" class="logo">
					<span>2021 Todos os direitos reservados</span>
					<div class="sociais">
						<i class="fab fa-facebook-f"></i>
						<i class="fab fa-instagram"></i>
						<i class="fab fa-whatsapp"></i>
						<i class="fab fa-youtube"></i>
					</div>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
export default {
	name: 'LayoutSite',
	methods: {
		toggleMenu(e) {
			if(e.target.className == 'menu-button' || e.target.className == 'content close-menu') {
				var element = document.querySelector('.menu-container');
				element.classList.toggle("active");
			}
		}
	},
}
</script>

<style scoped lang="scss">
	@font-face {
		font-family: montserrat-mediumitalic;
		src: url('~@/assets/fonts/montserrat-mediumItalic.ttf');
	}
	@font-face {
		font-family: montserrat-bolditalic;
		src: url('~@/assets/fonts/montserrat-bolditalic.ttf');
	}
	@font-face {
		font-family: montserrat-blackitalic;
		src: url('~@/assets/fonts/montserrat-blackitalic.ttf');
	}
	@font-face {
		font-family: the-barethos;
		src: url('~@/assets/fonts/the barethos.ttf');
	}
	@font-face {
		font-family: blueberry;
		src: url('~@/assets/fonts/blueberry days 400.otf');
	}
	@font-face {
		font-family: villainteamup;
		src: url('~@/assets/fonts/villainteamup.ttf');
	}
	@font-face {
		font-family: fair-prosper;
		src: url('~@/assets/fonts/Fair Prosper.ttf');
	}

	.main {
		.topo {
			padding: 10px 0;
			display: flex;
			justify-content: space-between;

			.menu-button {
				position: relative;
				width: 20px;
				height: 14px;
				border-top: 2px solid #005c9c;
				border-bottom: 2px solid #005c9c;
				cursor: pointer;
				visibility: hidden;

				&:after {
					content: '';
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					width: 100%;
					border-top: 2px solid #005c9c;
				}
			}
			
			.sociais {
				display: flex;
				gap: 15px;
				font-size: 18px;
				color: #005c9c;
			}
		}
		.menu-container {
			position: relative;
			background: #005c9c;
			z-index: 1;

			.menu {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr 190px 1fr 1fr 1fr;
				text-align: center;

				a {
					padding: 30px 10px;
					display: inline-block;
					color: white;
					font-weight: 600;
					align-self: center;
				}
				.img {
					position: relative;

					img {
						position: absolute;
						top: 0;
						left: 50%;
						transform: translateX(-50%);
						background: #91d8f6;
						padding: 20px 30px;
						border-bottom-left-radius: 10px;
						border-bottom-right-radius: 10px;
					}
				}
			}
		}
		.body {
			min-height: calc(100vh - 212px);
		}
		.footer-container {
			background: #005c9c;

			.footer {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 20px 0;
				color: white;
				text-align: center;

				.logo {
					width: 200px;
					height: 50px;
					object-fit: contain;
				}
				.sociais {
					display: flex;
					justify-content: flex-end;
					gap: 20px;
					width: 200px;
					font-size: 26px;
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.main {
			.topo {
				.menu-button {
					visibility: visible;
				}
			}
			.menu-container {
				position: fixed;
				top: 0;
				bottom: 0;
				left: -100%;
				width: 100%;
				z-index: 10;
				background: #777777b0;
				transition: 0.3s ease all;

				&.active {
					left: 0;
				}

				.content {
					all: unset;

					.menu {
						position: relative;
						display: flex;
						flex-direction: column;
						height: 100%;
						width: 250px;
						background: #005c9c;
						padding-top: 250px;
						text-align: left;

						a {
							align-self: auto;
							padding: 15px;
						}

						.img {
							position: absolute;
							top: 0;
							left: 0;
							right: 0;

							img {
								width: 100%;
								height: 250px;
								border-radius: 0;
								object-fit: cover;
							}
						}
					}
				}
			}
			.footer-container {
				.footer {
					flex-direction: column-reverse;
					gap: 15px;

					.sociais {
						justify-content: center;
					}
				}
			}
		}
	}
</style>
