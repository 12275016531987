<template>
	<div class="home">
		<h1>admin config</h1>
	</div>
</template>

<script>
import LayoutAdmin from '../layouts/LayoutAdmin.vue';

export default {
	name: 'Home',
	created() {
		this.$emit('update:layout', LayoutAdmin);
	},
}
</script>

<style>

</style>