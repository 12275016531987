import { createWebHistory, createRouter } from 'vue-router';

import Home from './components/site/Home.vue'
import QeumSomos from './components/site/QuemSomos.vue'
import Lojas from './components/site/Lojas.vue'
import Produtos from './components/site/Produtos.vue'
import Franqueado from './components/site/Franqueado.vue'
import FaleConosco from './components/site/Fale-conosco.vue'

import Configuracao from './components/admin/Configuracao.vue'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/quem-somos',
		name: 'Quem-somos',
		component: QeumSomos,
	},
	{
		path: '/lojas',
		name: 'Lojas',
		component: Lojas,
	},
	{
		path: '/produtos',
		name: 'Produtos',
		component: Produtos,
	},
	{
		path: '/franqueado',
		name: 'Franqueado',
		component: Franqueado,
	},
	{
		path: '/fale-conosco',
		name: 'FaleConosco',
		component: FaleConosco,
	},
	//admin
	{
		path: '/admin/configuracao',
		name: 'Configuracao',
		component: Configuracao,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;