<template>
	<div class="produtos">
		<div class="content">
			<h1	class="titulo"><b>Mais</b> do que uma <b>sorveteria,</b> uma <b>experiência</b></h1>
			<p class="subtitulo">É <b>#loucoporsorvete?</b> Entã você <b>não vai</b> resistir aos nossos produtos! Dos sorvetes <b>100% fuit,</b> passando pelos <b>cremosos</b> e até o <b>0 açúcar...</b></p>
			<p class="subtitulo">Se quiser incrementar, temos mais de <b>70 adicionasi</b> e <b>outras delícias</b> para acompanhar. Ah, não esquece de visitar nossa <b>lojinha!</b></p>

		</div>

		<div class="catalogo">
			<div class="content">
				<img class="titulo" :src="require('@/assets/palito-2.png')" alt="Conheça nossos produtos">

				<div class="categorias">
					<div class="categoria active">SORVETES BERRY'S</div>
					<div class="categoria">SORVETES BERRY'S</div>
					<div class="categoria">SORVETES BERRY'S</div>
					<div class="categoria">SORVETES BERRY'S</div>
				</div>

				<div class="subcategoria">
					<div class="sub-titulo">Ao Leite</div>
					<div class="itens">
						<div class="item">
							<img :src="require('@/assets/chocolate-belga.jpg')" alt="Chocolate belga">
							<div class="descricao">
								<p class="nome">Chocolate Belga</p>
								<p class="ingredientes">Feito com cacau, leite e pedaços de chocolate belga. (base leite, contém glúten)</p>
							</div>
						</div>
						<div class="item">
							<img :src="require('@/assets/chocolate-belga.jpg')" alt="Chocolate belga">
							<div class="descricao">
								<p class="nome">Chocolate Belga</p>
								<p class="ingredientes">Feito com cacau, leite e pedaços de chocolate belga. (base leite, contém glúten)</p>
							</div>
						</div>
						<div class="item">
							<img :src="require('@/assets/chocolate-belga.jpg')" alt="Chocolate belga">
							<div class="descricao">
								<p class="nome">Chocolate Belga</p>
								<p class="ingredientes">Feito com cacau, leite e pedaços de chocolate belga. (base leite, contém glúten)</p>
							</div>
						</div>
					</div>
				</div>

				<div class="subcategoria">
					<div class="sub-titulo">100% Fruit</div>
					<div class="itens">
						<div class="item">
							<img :src="require('@/assets/chocolate-belga.jpg')" alt="Chocolate belga">
							<div class="descricao">
								<p class="nome">Chocolate Belga</p>
								<p class="ingredientes">Feito com cacau, leite e pedaços de chocolate belga. (base leite, contém glúten)</p>
							</div>
						</div>
						<div class="item">
							<img :src="require('@/assets/chocolate-belga.jpg')" alt="Chocolate belga">
							<div class="descricao">
								<p class="nome">Chocolate Belga</p>
								<p class="ingredientes">Feito com cacau, leite e pedaços de chocolate belga. (base leite, contém glúten)</p>
							</div>
						</div>
						<div class="item">
							<img :src="require('@/assets/chocolate-belga.jpg')" alt="Chocolate belga">
							<div class="descricao">
								<p class="nome">Chocolate Belga</p>
								<p class="ingredientes">Feito com cacau, leite e pedaços de chocolate belga. (base leite, contém glúten)</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LayoutSite from '../layouts/LayoutSite.vue';

export default {
	name: 'Produtos',
	created() {
		this.$emit('update:layout', LayoutSite);
	},
}
</script>

<style scoped lang="scss">
	.produtos {
		padding-top: 130px;
		text-align: center;

		.titulo {
			font-family: montserrat-bolditalic;
			font-size: 28px;
			color: #005c9c;

			b {
				font-family: montserrat-blackitalic;
			}
		}
		.subtitulo {
			margin-bottom: 0;
			font-family: montserrat-mediumitalic;
			font-size: 14px;

			b {
				font-family: montserrat-bolditalic;
			}
		}
		.catalogo {
			margin-top: 50px;
			min-height: 500px;
			background-image: url('~@/assets/tijolinhos.png');

			.titulo {
				max-width: 500px;
				margin-top: -40px;
			}

			.categorias {
				display: flex;
				flex-wrap: wrap;
				gap: 15px;
				margin-top: 15px;

				.categoria { 
					border: 3px solid #005c9c;
					border-radius: 10px;
					background: white;
					padding: 3px 7px;
					color: #005c9c;
					font-weight: 600;

					&.active {
						background: #005c9c;
						color: white;
					}
				}
			}
			.subcategoria {
				margin-top: 30px;
				padding-bottom: 30px;

				.sub-titulo {
					font-family: the-barethos;
					font-size: 48px;
					color: #005c9c;
				}
				.itens {
					position: relative;
					display: grid;
					grid-template-columns: 1fr 1fr;
					grid-column-gap: 150px;

					&::after {
						content: '';
						position: absolute;
						top: 0;
						bottom: 0;
						left: 50%;
						width: 3px;
						background: #005c9c;
					}

					.item {
						display: flex;
						gap: 15px;
						margin-bottom: 30px;

						img {
							width: 150px;
							height: 150px;
							object-fit: cover;
							border-radius: 10px;
						}
						.descricao {
							display: flex;
							flex-direction: column;
							justify-content: center;
							text-align: left;

							.nome {
								font-size: 28px;
								font-weight: 600;
							}
							.ingredientes {
								margin: 0;
								line-height: 18px;
							}
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.produtos {
			padding-top: 30px;

			.catalogo {
				.titulo {
					width: 300px;
					margin-top: -30px;
				}
				.categorias {
					.categoria {
						width: 100%;
					}
				}
				.subcategoria {
					.sub-titulo {
						margin-bottom: 15px;
					}
					.itens {
						grid-template-columns: 1fr;

						&:after {
							content: none;
						}
					}
				}
			}
		}
	}
</style>