<template>
	<div class="fale-conosco">
		<div class="content">
			<h1 class="titulo"><b>Dúvidas, elogos?</b> Queromos saber sua opinião!</h1>

			<h2 class="subtitulo">Fale conosco</h2>

			<form action="">
				<input type="text" name="nome" placeholder="Nome">
				<input type="email" name="email" placeholder="E-mail">
				<input type="text" name="cidade" placeholder="Cidade">
				<input type="text" name="telefone" placeholder="Telefone">
				<input type="text" name="assunto" placeholder="Assunto">
				<textarea name="mensagem" rows="10" placeholder="Mensagem"></textarea>
				<button type="submit">ENVIAR MENSAGEM</button>
			</form>

			<h2 class="subtitulo">Atividades Recentes</h2>
		</div>
	</div>
</template>

<script>
import LayoutSite from '../layouts/LayoutSite.vue';

export default {
	name: 'Produtos',
	created() {
		this.$emit('update:layout', LayoutSite);
	},
}
</script>

<style scoped lang="scss">
	.fale-conosco {
		padding-top: 130px;
		text-align: center;

		.titulo {
			font-family: montserrat-bolditalic;
			font-size: 28px;

			b {
				font-family: montserrat-blackitalic;
			}
		}
		.subtitulo {
			font-family: the-barethos;
			color: #005c9c;
			font-size: 36px;
		}
		form {
			margin-top: 30px;
			margin-bottom: 50px;
			display: flex;
			flex-wrap: wrap;
			gap: 10px;

			input, textarea {
				flex-basis: calc(50% - 5px);
				border: 2px solid #005c9c;
				border-radius: 10px;
				padding: 5px;
				color: #005c9c;
				resize: none;

				&[name="cidade"] {
					flex-basis: 100%;
				}
				&::placeholder {
					color: #005b9ca4;
				}
			}
			textarea {
				flex-basis: 100%;
			}
			button {
				background: #0169b2;
				border: 1px solid #0169b2;
				border-radius: 7px;
				padding: 5px 10px;
				color: white;
				font-weight: 600;
				font-size: 14px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.fale-conosco {
			padding-top: 30px;

			form {
				input, textarea {
					flex-basis: 100%;
				}
			}
		}
	}
</style>