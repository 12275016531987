<template>
	<component :is="layout">
		<router-view v-model:layout="layout"/>
	</component>
</template>

<script>
export default {
	name: 'App',
	data() {
		return {
			layout: 'div',
		};
	},
};
</script>

<style>
	.content {
		width: 100%;
		max-width: 1300px;
		margin: auto;
		padding: 0 15px;
	}
</style>
